<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/invoice.png" alt />
        <span class="center">项目管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">项目列表</span>
      </div>

      <div style="display: flex; align-items: center">
        <div style="display: flex; align-items: center">
          <span class="seach_text">关键字:</span>
          <el-input
            placeholder="请输入公司名称"
            v-model="keywords"
            @input="loadData('seath')"
            size="mini"
            style="margin-right: 10px; width: 250px"
            prefix-icon="el-icon-search"
            clearable
          ></el-input>
        </div>
        <el-button
          style="margin-left: 20px"
          v-access
          data-ctrl="projectAdd"
          type="primary"
          size="small"
          icon="el-icon-plus"
          @click="addProject()"
        >新建</el-button>
      </div>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column label="项目名称">
        <template slot-scope="scope">
          <span class="clickColor" @click="goDetail(scope.row.code)">
            {{
            scope.row.name
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="负责人">
        <template slot-scope="scope">
          <span v-if="scope.row.subject">
            {{
            scope.row.subject.name || scope.row.subject.nickname
            }}
          </span>
        </template>
      </el-table-column>

      <el-table-column label="状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === '1'">正常</el-tag>
          <el-tag type="success" v-else>结束</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="合作企业">
        <template slot-scope="scope">
          <span>{{ scope.row.companyName || '' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="合同金额(￥)">
        <template slot-scope="scope">
          <span>{{ scope.row.contractMoney | money }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" width="200px">
        <template slot-scope="scope">
          <span>{{ scope.row.create_time | dateVal }}</span>
        </template>
      </el-table-column>
      <el-table-column label="结束时间">
        <template slot-scope="scope">
          <span>{{ scope.row.endTime || "项目暂未结束" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button v-access data-ctrl="projectEdit" @click="goedit(scope.row.code)" type="text">编辑</el-button>
          <!-- <el-button @click="getSeeInfo(scope.row.code)" type="text">查看账户信息</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor" style="margin: 0 10px">每页{{ pageSize }}条</span>
      <el-pagination
        background
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev,pager, next,jumper"
        :total="total"
      ></el-pagination>
    </div>
    <el-dialog class="showImgDialog" :title="dialogTitle" :visible.sync="infoVisible">
      <div slot="title" style="text-align:center">
        <span style="font-size:16px;font-weight:700">账户信息</span>
        <p style="color: #999;;font-weight:700">{{enterpriseName}}</p>
      </div>
      <div>
        <div class="demo_class">
          <p>开户行：平安银行</p>
          <p>分行/支行：平安银行股份有限公司西安分行</p>
          <p>联行号：123456789012</p>
          <p>账户卡号：12345678901234</p>
        </div>
        <div class="demo_class">
          <p>开户行：平安银行</p>
          <p>分行/支行：平安银行股份有限公司西安分行</p>
          <p>联行号：123456789012</p>
          <p>账户卡号：12345678901234</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { api } from '../../api/base/index';
export const projectList = api()('project.list.json');

export default {
  data () {
    return {
      tableData: [],
      dialogTitle: '',
      keywords: '',
      infoVisible: false,
      dialogImgUrl: '',
      pageSize: 10,
      pageNumber: 1,
      total: 0,
      enterpriseName: ''
    }
  },
  created () {
    this.enterpriseName = window.localStorage.getItem('name')
    this.loadData()
  },
  methods: {
    // showImg (img, title) {
    //   this.dialogImgUrl = img;
    //   this.dialogTitle = title;
    //   console.log(this.dialogImgUrl);
    //   this.showImgVisible = true;
    // },
    addProject () {
      this.$router.push({
        path: '/system/enterpriseProject/add',
      });
    },
    handleSizeChange (val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange (val) {
      this.pageNumber = val;
      this.loadData();
    },
    loadData (seath) {
      if (seath) {
        this.pageNumber = 1;
      }
      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        keywords: this.keywords
      };
      projectList(params).then((res) => {
        for (let i = 0; i < res.list.length; i++) {
          res.list[i].passShow = false
        }
        this.tableData = res.list;
        console.log(this.tableData)
        this.total = res.total;
      });
    },
    goShow (code) {

      this.tableData.forEach(it => {
        if (it.code === code) {
          console.log(it)
          it.passShow = !it.passShow
        }
      })
    },
    getSeeInfo (code) {
      console.log(code);
      this.infoVisible = true
    },
    goedit (code) {
      this.$router.push({
        path: '/system/enterpriseProject/add',
        query: { code },
      });
    },
    goDetail (code) {
      this.$router.push({
        path: '/system/enterpriseProject/detail',
        query: { code },
      });
    }
  },
}
</script>
<style lang="scss" scope>
// .showImgDialog .imgBox {
//   display: flex;
// }
// .img-resource {
//   width: 300px;
//   height: 250px;
//   margin: 0 auto;
// }
.demo_class {
  padding: 10px;
  border-bottom: 2px solid #eee;
}
.demo_class:nth-last-child(1) {
  border-bottom: none;
}
</style>